import React, { useState, useEffect } from 'react';
import './EventsActivities.css';

import img1 from "./assets/Events/Plantation.jpg";
import img2 from "./assets/Events/mentoring.jpg";
import img3 from "./assets/Events/Organic.png";
import img4 from "./assets/Events/Workshop.jpg";
import img5 from "./assets/Events/CODE-EVENT.jpg";
import img6 from "./assets/Events/Alumni-Meet.jpg";
import img7 from "./assets/Events/Organic-agriculture.jpg";
import img8 from "./assets/Events/USA.jpg";
import ChitkaraUni13Sep24TechShow from "./assets/Events/ChitkaraUni-13Sep24-TechShow.jpg";
import CCChandigarh21Sep24 from "./assets/Events/3C-Chandigarh-21Sep24.jpg";
import TiEChandigarhAwardsNight21Sep24 from "./assets/Events/TiE Chandigarh Awards Night-21Sep-24.jpg";
import GrowthSummitDelhivery from "./assets/Events/GrowthSummitDelhivery-9Aug2024.jpg";
import ChandigarhEntMeet28Sep24 from "./assets/Events/ChandigarhEntreperneursMeet-28September2024.jpg";
import ChandigarhEntMeet2Nov24 from "./assets/Events/ChandigarhEntreperneursMeet-2Nov24.jpg";
import ICPHubsCrewsphereVCBrunch from "./assets/Events/ICPHubCrewsphereVCBRUNCH.jpeg";


const slides = [
  {
    id: 1,
    image: [ChitkaraUni13Sep24TechShow],
    title: 'Chitkara University - Tech Show',
    date: '13 Sep 2024',
    description: "As a Judge and Guest of Honor at the tech show held at Chitkara University, I had the privilege of evaluating over 200 innovative projects presented by talented students. It was an inspiring experience to witness the creativity, technical acumen, and dedication demonstrated by each participant. This event highlighted the promising future of technology and the exceptional talent emerging from Chitkara University. I was truly honored to be part of this showcase, supporting young innovators as they shared their groundbreaking ideas and solutions."
  },
  {
    id: 2,
    image: [CCChandigarh21Sep24],
    title: 'Building a Community',
    date: '21 Sep 2024',
    description: "I recently embarked on building a vibrant community called 'Content Creators Chandigarh' to bring together passionate content creators from the region. It was a proud moment to host our first meeting at one of our co-working spaces in Sector 22, Chandigarh. The gathering was a fantastic opportunity for creators to network, share insights, and collaborate on ideas. This community aims to support and inspire each other, fostering growth and innovation within the local content creation scene.ogrganised and participated in a meaningful plantation drive as part of a Corporate Social Responsibility (CSR) initiative. This event was a testament to our commitment to environmental sustainability and community engagement. We planted a variety of native trees and shrubs, contributing to local biodiversity and combating climate change. The drive not only enhanced the green cover but also fostered a sense of teamwork and responsibility among participants. It was rewarding to see the immediate impact of our efforts and know that we are contributing to a greener, healthier planet."
  },
  {
    id: 3,
    image: [TiEChandigarhAwardsNight21Sep24],
    title: 'TiE Chandigarh Awards Night',
    date: '21 Sep 2024',
    description: "I was honored to be invited by the president of TiE Chandigarh to attend both the TiE Chandigarh Awards Night and the inaugration of Global Summit TiE Bangalore. These prestigious events bring together leaders, innovators, and entrepreneurs to celebrate achievements and foster connections within the entrepreneurial ecosystem. It was an incredible opportunity to witness inspiring moments, gain insights, and engage with influential members of the business and startup community."
  },
  {
    id: 4,
    image: [GrowthSummitDelhivery],
    title: 'Growth Summit - Delhivery',
    date: '9 Aug 2024',
    description: "I ogrganised and participated in a meaningful plantation drive as part of a Corporate Social Responsibility (CSR) initiative. This event was a testament to our commitment to environmental sustainability and community engagement. We planted a variety of native trees and shrubs, contributing to local biodiversity and combating climate change. The drive not only enhanced the green cover but also fostered a sense of teamwork and responsibility among participants. It was rewarding to see the immediate impact of our efforts and know that we are contributing to a greener, healthier planet."
  },
  {
    id: 5,
    image: [ChandigarhEntMeet28Sep24],
    title: 'Chandigarh Entreperneurs Meet',
    date: '28 Sep 2024',
    description: "I recently attended a meetup organized by Chandigarh Entrepreneurs, a dynamic group dedicated to fostering entrepreneurship in the region. The event underscored the value of networking, with entrepreneurs exchanging insights, sharing challenges, and discovering potential collaborations. Mentorship was another key focus, as experienced professionals offered guidance and shared their journeys, creating an enriching learning environment. Events like these are essential for personal and professional growth, as they provide access to valuable advice, diverse perspectives, and meaningful connections that drive progress in the entrepreneurial landscape."
  },
  {
    id: 6,
    image: [ICPHubsCrewsphereVCBrunch],
    title: 'ICP Hubs - Crewsphere VC Brunch',
    date: '20 October 2024',
    description: "I had the privilege of attending an exclusive VC brunch on October 20, 2024, hosted by ICP Hub India - Crewsphere. This gathering brought together venture capitalists, industry leaders, and innovators in an intimate setting, fostering insightful discussions on investment trends and startup growth. The brunch was a unique opportunity to connect with key players in the investment ecosystem, exchange perspectives, and explore collaborative possibilities in a relaxed yet impactful environment."
  },
  {
    id: 7,
    image: [ChandigarhEntMeet2Nov24],
    title: 'Chandigarh Entreperneurs Meet',
    date: '2 Nov 2024',
    description: "On November 2, 2024, I attended a meetup by Chandigarh Entrepreneurs, which brought together a vibrant community of business minds and startup enthusiasts. This gathering emphasized the importance of networking, as it allowed participants to connect, exchange ideas, and build relationships that could lead to future collaborations. Mentorship was a key highlight, with seasoned entrepreneurs sharing valuable insights and experiences, creating an inspiring atmosphere for learning. Such events play a crucial role in empowering entrepreneurs by providing a platform for knowledge exchange and support within the local business ecosystem."
  },
  {
    id: 8,
    image: [img1],
    title: 'Plantation Drive',
    date: '6 Aug 2021',
    description: "I ogrganised and participated in a meaningful plantation drive as part of a Corporate Social Responsibility (CSR) initiative. This event was a testament to our commitment to environmental sustainability and community engagement. We planted a variety of native trees and shrubs, contributing to local biodiversity and combating climate change. The drive not only enhanced the green cover but also fostered a sense of teamwork and responsibility among participants. It was rewarding to see the immediate impact of our efforts and know that we are contributing to a greener, healthier planet."
  },
  {
    id: 9,
    image: [img2],
    title: 'Mentorship Session @ CGC Jhanjeri',
    date: 'May 2023',
    description: "I had the privilege of conducting a mentorship session at a College. Interacting with the next generation of leaders and innovators was incredibly rewarding. We discussed career paths, the importance of adaptability in the tech industry, and strategies for continuous learning and personal growth. The enthusiasm and curiosity of the students were inspiring, reaffirming the value of mentorship in shaping future professionals. Engaging with young minds is a fulfilling way to give back and contribute to their growth and success."},
  {
    id: 10,
    image: [img3],
    title: 'Interviewing to spread word Organic',
    date: 'Mar 2023',
    description: "I engaged in farming as a project focused on natural cultivation, emphasizing the production of 100% organic and natural produce. This initiative not only ensures healthier food options but also significantly contributes to environmental sustainability. By adopting eco-friendly farming practices, we minimize chemical usage, enhance soil health, and promote biodiversity. This approach not only yields nutritious crops but also preserves the ecosystem for future generations. It's a fulfilling endeavor to contribute to a sustainable future while providing clean, healthy food."
  },
  {
    id: 11,
    image: [img4],
    title: 'Bridge to AI - Python Workshop',
    date: 'April 2024',
    description: 'I recently had the opportunity to participate in a "Bridge to AI - Python Workshop" a dynamic event designed to introduce participants to the fundamentals of Python programming in the context of artificial intelligence. The workshop covered essential Python concepts, data manipulation techniques, and basic AI algorithms. Through hands-on coding sessions and real-world examples, attendees gained practical skills and insights into how Python can be leveraged to solve complex problems in AI. This enriching experience not only boosted technical proficiency but also sparked a deeper interest in AI innovations.',
  },
  {
    id: 12,
    image: [img5],
    title: 'Code Event - New Delhi',
    date: 'June 2023',
    description: "The CODE 2023 event, hosted by Expand My Business, is set to be India's largest digital convention, taking place on June 10-11 at Pragati Maidan, New Delhi. This landmark event aims to bring together entrepreneurs, investors, and industry leaders to explore business growth, investment opportunities, and mentorship. Over two action-packed days, attendees will engage in six major events, including the Founders' Conference, Investor Summit, and various specialized conferences focusing on MarTech and HR Tech.",
  },
  {
    id: 13,
    image: [img6],
    title: 'Alumni Meet @ CGC Jhanjeri',
    date: 'May 2024',
    description: 'The recent college alumni meet at Tim Hortons was a vibrant networking event, bringing together past graduates for an evening of connection and camaraderie. The gathering provided an excellent opportunity for alumni to reconnect, share professional experiences, and explore collaborative opportunities. With a warm and inviting atmosphere, attendees enjoyed engaging conversations over coffee, reigniting old friendships, and forming new professional connections. The event was a testament to the enduring bonds formed during college years and highlighted the ongoing value of our alumni community.',
  },
  {
    id: 14,
    image: [img7],
    title: 'Organic Meetup - Greener Future',
    date: 'July 2023',
    description: 'The agriculture event in Kurukshetra brought together farmers, experts, and enthusiasts to discuss sustainable natural farming. The gathering provided a platform for sharing innovative practices, exploring eco-friendly techniques, and networking with like-minded individuals. Attendees engaged in insightful discussions on organic cultivation methods, environmental preservation, and enhancing soil health. The event highlighted the collective commitment to fostering sustainable agriculture and building a greener future.',
  },
  {
    id: 15,
    image: [img8],
    title: 'Business Tour - USA 2023',
    date: 'Sept 2023',
    description: 'During my recent visit to the US, I was struck by the diversity of cultures and perspectives I encountered. Traveling abroad opens eyes to new ways of thinking and living, challenging preconceptions and broadening my worldview. It helps in making connections with people from various backgrounds, each offering unique insights and opportunities. This experience expandes horizon of growth, both personally and professionally. It made me realize that stepping out of our comfort zones and immersing ourselves in different environments can spark creativity, foster adaptability, and unlock potential we never knew existed. The memories and lessons from this journey will undoubtedly shape my future endeavors.'},
];

function EventsActivities() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 8000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handlePrevClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  const { image, title, date, description } = slides[currentSlide];
  return (
    <div className="events-activities">
      <div className="triangle-container">
        <div className="triangle triangle1"></div>
        <div className="triangle triangle2"></div>
        <div className="triangle triangle3"></div>
      </div>
      <header className="events-header">
        <h1>Events & Activities</h1>
        <p>Here are a few recent events!</p>
      </header>
      <main className="events-main">
        <button className="slider-button prev" onClick={handlePrevClick}>&lt;</button>
        <div className="events-content">
          <div className="events-slider">
            <img src={image} alt="Event" className="event-image" />
          </div>
          <div className="events-details">
            <h2>{title}</h2>
            <p className='date'>{date}</p>
            <p>{description}</p>
          </div>
        </div>
        <button className="slider-button next" onClick={handleNextClick}>&gt;</button>
      </main>
      <div className='line'></div>
      {/* <div className="details-button-container">
        <a href="https://www.linkedin.com/in/jigarjit-singh" target="_blank" rel="noopener noreferrer">
          <button className="details-button">VIEW ALL EVENT DETAILS</button>
        </a>
      </div> Button hidden due to page not ready*/}
    </div>
  );
}

export default EventsActivities;
