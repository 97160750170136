import React, { useState } from 'react';

import logo2 from "./assets/JJ-Singh-logo-02.png";
import DDT from "./assets/Slides/Slide1.png";
import slide2 from "./assets/Slides/artificial-intelligence-nanotechnologies-business-technologies-concept-futuristic-background-photo.jpg";
import slide3 from "./assets/Slides/Digital-Transformation-Consulting-2.webp";
import slide4 from "./assets/Slides/i-Stock-1160000498.jpg";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, 
    cssEase: 'ease-in-out', 
    fade: true,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  const slideData = [
    {
      image: [DDT],
      title: '"Driving Digital Transformation with AI Solutions and Entrepreneurship"',
      description:
        "***"
    },
    {
      image: [slide2],
      title: '"Unlocking Business Potential: Strategic Consulting and Startup Advisory"',
      description:
        "***"
    },
    {
      image: [slide3],
      title: '"AI Innovations and Legal Expertise: Journey in Transforming Businesses"',
      description:
        "***"
    },
    {
      image: [slide4],
      title: '"Empowering Entrepreneurs: AI Solutions and Start-up Strategies"',
      description:
        "***"
    },
  ];

  return (
    <div className="carousel-container">
      <img
        src={logo2} 
        alt="JJ Singh Logo"
        className="carousel-title"
      />
      <Slider {...settings}>
        {slideData.map((slide, index) => (
          <div key={index} className="carousel-slide">
            <img src={slide.image} alt={slide.title} className="carousel-image" />
            <div
              className="carousel-content"
              style={{ display: index === currentSlide ? "block" : "none" }}
            >
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;

