import React, { useState } from 'react';
import "./Investment.css";

import invLogos1 from "./assets/logos/AONE X US.png";
import invLogoBF from "./assets/logos/BegampuraFarm.png";
import invLogosCnC from "./assets/logos/Concepts and careers.png";
import invLogosCnCGU from "./assets/logos/growth-university-CnC.png";
import invLogosJWC from "./assets/logos/JW-Circle.png";
import invLogosINT from "./assets/logos/innonexus tech logo.png";
import invLogosJW from "./assets/logos/JWInfotech.png";
import invLogosJWJ from "./assets/logos/JW-Jewellers-logo.png";
import invLogosPR from "./assets/logos/PayRental-LOGO.png";
import invLogosNT from "./assets/logos/NexusTeq.png";
import invLogoPFT from "./assets/logos/perfect fitness team.png";
import invLogosTC from "./assets/logos/Tregcom-logo.jpeg";

const companyLogos = [
  { src: [invLogos1], alt: "AONE X US", link: "https://www.aonexus.com/" },
  { src: [invLogoBF], alt: "Begampura Farm", link: "https://www.facebook.com/begampurafarm/" },
  { src: [invLogosCnC], alt: "Concepts and Careers", link: "https://conceptsandcareers.com/" },
  { src: [invLogosCnCGU], alt: "Growth University CnC", link: "https://growthuniversity.conceptsandcareers.com/" },
  { src: [invLogosINT], alt: "Innonexus Tech", link: "https://innonexus.tech/" },
  { src: [invLogosJWC], alt: "JW Circle", link: "https://jwinfo.tech/" },
  { src: [invLogosJW], alt: "JW Infotech", link: "https://jwinfo.tech/" },
  { src: [invLogosJWJ], alt: "JW Jewellers", link: "http://www.jwjewellers.com/" },
  { src: [invLogosPR], alt: "Pay Rental", link: "https://payrental.co.in/" },
  { src: [invLogosNT], alt: "NexusTeq", link: "https://nexusteq.com/" },
  { src: [invLogoPFT], alt: "Perfect Fitness Team", link: "https://perfectfitnessteam.co.in/" },
  { src: [invLogosTC], alt: "Tregcom", link: "https://www.facebook.com/tregcom.in/" }
];

const Investment = () => {
  const [hoveredLogoIndex, setHoveredLogoIndex] = useState(null);
  return (
    <div className="investment">
      <section className="instructions-box">
        <h1>Investments & Companies</h1>
        <div className="instructions">
          <p>Throughout my career, I have founded and invested in a diverse portfolio of companies, each representing a unique facet of my entrepreneurial vision. JW Infotech stands as a leading Information Technology solutions provider, delivering innovative technology solutions to help businesses grow and thrive. Concepts and Careers, along with its flagship initiative Growth University, focuses on nurturing talent and providing advanced educational programs to equip individuals with essential skills for today's dynamic job market.</p>
          <p>InnoNexus embodies the spirit of innovation, developing cutting-edge technologies that address real-world challenges across various industries. Begampura Farm is committed to sustainable agriculture, employing modern techniques to produce high-quality, organic products while promoting eco-friendly farming practices. Perfect Fitness Team offers personalized fitness and wellness solutions designed to help individuals achieve their health goals and maintain a balanced lifestyle.</p>
          <p>AOneXus provides comprehensive business solutions, including consulting services and technology implementation, to help businesses streamline operations and achieve sustainable growth. PayRental revolutionizes property management with an innovative platform that ensures a seamless experience for landlords and tenants. NexusTeq delivers advanced technology solutions tailored to meet the unique needs of businesses, encompassing software development, Information Technology consulting, and system integration.</p>
          <p>TREGCOM - The Real Estate Group of Companies excels in property development and management, offering services from residential and commercial projects to property investment advisory. JW EXIM specializes in import and export services, facilitating international trade and ensuring the smooth exchange of goods and services across borders. Finally, JW Jewellers celebrates elegance and craftsmanship with exquisite jewelry collections designed to cater to diverse tastes.</p>
          <p>These ventures reflect my commitment to innovation, sustainability, and excellence, each company serving as a testament to my belief in creating value and driving positive change across various industries.</p>
        </div>
        <div className="button-container">
          <a
            href="https://www.linkedin.com/in/jigarjit-singh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="learn-more">Know More</button>
          </a>
        </div>
      </section>

      <section className="companies-box">
        {companyLogos.map((logo, index) => (
          <a
            href={logo.link}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            className="company-link"
            onMouseEnter={() => setHoveredLogoIndex(index)}
            onMouseLeave={() => setHoveredLogoIndex(null)}
          >
            <div className="company-logo">
              <img src={logo.src} alt={logo.alt} />
              {hoveredLogoIndex === index && (
                <div className="view-more-overlay">
                  <span className="view-more-text">View More → </span>
                </div>
              )}
            </div>
          </a>
        ))}
      </section>
    </div>
  );
};

export default Investment;
